import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import palette from 'theme/palette';
import { QaPhantomLimits, getQaLimits, getSliceNum } from './components/QaEntry.util';

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  formTitle: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 16,
    color: palette.primary.main,
  },
  scannerInfo: {
    fontSize: 10,
    opacity: 0.8,
    textAlign: 'left',
    marginBottom: 0,
  },
  copyRight: {
    padding: 4,
    paddingTop: 40,
    textAlign: 'center',
    fontSize: 10,
    opacity: 0.8,
    color: palette.primary.main,
  },
  shortWidth: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '25.2x',
    borderWidth: 0.1,
  },
  name: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '68px',
    borderWidth: 0.1,
  },
  noteWidth: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '125px',
    borderWidth: 0.1,
  },
  thirdWidth: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '33.6px',
    borderWidth: 0.1,
  },
  headNumber: {
    fontSize: 7,
    textAlign: 'center',
    width: '50.4px',
    paddingTop: 0,
    borderWidth: 0.1,
  },
  dbHeadNumber: {
    fontSize: 7,
    textAlign: 'center',
    width: '50.4px',
    paddingTop: 3,
    borderWidth: 0.1,
  },
  date: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '25.2x',
    borderWidth: 0.1,
    paddingTop: 8,
  },
  setUp: {
    fontSize: 7.25,
    textAlign: 'center',
    width: '100.8px',
    paddingTop: 5,
    borderWidth: 0.1,
    display: 'flex',
    flexDirection: 'colum',
  },
  FreqTXGain: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '50.4px',
    paddingTop: 5,
    borderWidth: 0.1,
  },
  lowContrastDetect: {
    fontSize: 7,
    textAlign: 'center',
    width: '55.4px',
    paddingTop: 0,
    borderWidth: 0.1,
  },
  numOfSpoke: {
    fontSize: 7,
    textAlign: 'center',
    width: '55.4px',
    paddingTop: 4,
    borderWidth: 0.1,
  },
  geoAcc: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '100.8px',
    paddingTop: 4,
    borderWidth: 0.1,
  },
  highContrast: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '67.2px',
    paddingTop: 5,
    borderWidth: 0.1,
  },
  testedReviewedBy: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '68px',
    paddingTop: 8,
    borderWidth: 0.1,
  },
  notes: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '125px',
    borderWidth: 0.1,
    paddingTop: 8,
  },
  accuracyConsoleSpoke: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '50.4px',
    paddingTop: 3,
    borderWidth: 0.1,
  },
  HPAPRL: {
    fontSize: 7.5,
    textAlign: 'center',
    width: '33.6px',
    borderWidth: 0.1,
    paddingTop: 4,
  },
  header: {
    fontSize: 8,
    textAlign: 'right',
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
});

function QaPdfPreview(props) {
  const { records, device } = props;

  const parseDate = (input) => {
    const date = new Date(input);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);

    return `${month}/${day}/${year}`;
  };

  return (
    <Document>
      {records
        ? records.map((record) => {
            const [cfLimits, tgLimits] = getQaLimits(record.entries[0]);
            const sliceNumber = getSliceNum(device.fieldStrength);
            const phantomLimits = QaPhantomLimits[device.phantomSize];
            return (
              <Page orientation="landscape" key={record.entries[0]._id} style={styles.body}>
                <View fixed style={styles.footer}>
                  <Text
                    style={styles.header}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                  />
                  <Text fixed style={styles.header}>
                    Provided By Radiology QC Solutions
                  </Text>
                </View>
                <Text style={styles.formTitle}>MRI Equipment Quality Control Data Form</Text>
                <View key={record.entries[0]._id} style={{ marginBottom: 24, marginTop: 32, marginLeft: 32 }}>
                  <Text style={styles.scannerInfo}>Facility : {device.facilityName}</Text>
                  <Text style={styles.scannerInfo}>Scanner : {`${device.name} (${device.apNumber})`}</Text>
                </View>
                <View style={{ margin: 32, borderWidth: 1 }}>
                  <View style={{ borderBottomWidth: 0.1, flexDirection: 'row' }}>
                    <Text style={styles.thirdWidth}>1</Text>
                    <Text style={styles.headNumber}>2</Text>
                    <Text style={styles.headNumber}>3</Text>
                    <Text style={styles.headNumber}>4</Text>
                    <Text style={styles.headNumber}>5</Text>
                    <Text style={styles.thirdWidth}>6</Text>
                    <Text style={styles.thirdWidth}>7</Text>
                    <Text style={styles.thirdWidth}>8</Text>
                    <Text style={styles.thirdWidth}>9</Text>
                    <Text style={styles.thirdWidth}>10</Text>
                    <Text style={styles.lowContrastDetect}>11</Text>
                    <Text style={styles.headNumber}>12</Text>
                    <Text style={styles.name} />
                    <Text style={styles.noteWidth} />
                    <Text style={styles.name} />
                  </View>

                  <View style={{ borderBottomWidth: 0.1, flexDirection: 'row' }}>
                    <Text style={styles.thirdWidth}>Date</Text>

                    <View style={styles.setUp}>
                      <Text>Table Setup</Text>
                      <Text> / Position Accuracy</Text>
                    </View>

                    <Text style={styles.FreqTXGain}>Ctr Freq (Hz)</Text>
                    <Text style={styles.FreqTXGain}>TX Gain /Attn. (dB)</Text>
                    <Text style={styles.geoAcc}>Geo. Accuracy Measurement (mm)</Text>
                    <Text style={styles.highContrast}>High Contrast Spatial Resol. (mm)</Text>
                    <Text style={styles.lowContrastDetect}>Low Contrast Detectability Slice #: {sliceNumber}</Text>
                    <Text style={styles.FreqTXGain}>Any Present of Artifact Eval?</Text>
                    <Text style={styles.testedReviewedBy}>Tested By</Text>
                    <Text style={styles.notes}>Notes</Text>
                    <Text style={styles.testedReviewedBy}>Reviewed By</Text>
                  </View>

                  <View style={{ borderBottomWidth: 0.1, flexDirection: 'row' }}>
                    <Text style={styles.thirdWidth} />
                    <Text style={styles.accuracyConsoleSpoke}>Accuracy OK?</Text>
                    <Text style={styles.accuracyConsoleSpoke}>Console OK?</Text>
                    <Text style={styles.headNumber}>{cfLimits}</Text>
                    <Text style={styles.dbHeadNumber}>{tgLimits}</Text>
                    <Text style={styles.HPAPRL}>HF</Text>
                    <Text style={styles.HPAPRL}>A/P</Text>
                    <Text style={styles.HPAPRL}>R/L</Text>
                    <Text style={styles.thirdWidth}>Upper Left</Text>
                    <Text style={styles.thirdWidth}>Lower Right</Text>
                    <Text style={styles.numOfSpoke}># of Spoke</Text>
                    <Text style={styles.headNumber} />
                    <Text style={styles.name} />
                    <Text style={styles.noteWidth} />
                    <Text style={styles.name} />
                  </View>

                  <View
                    style={{
                      borderBottomWidth: 0.1,
                      flexDirection: 'row',
                      color: `${palette.primary.main}`,
                    }}
                  >
                    <Text style={styles.thirdWidth}>Limit</Text>
                    <Text style={styles.headNumber}>± 5mm</Text>
                    <Text style={styles.headNumber}>Y/N</Text>
                    <Text style={styles.headNumber} />
                    <Text style={styles.headNumber} />
                    <Text style={styles.thirdWidth}>{phantomLimits.geoHf} ± 2</Text>
                    <Text style={styles.thirdWidth}>{phantomLimits.geoApRl} ± 2</Text>
                    <Text style={styles.thirdWidth}>{phantomLimits.geoApRl} ± 2</Text>
                    <Text style={styles.thirdWidth}>≤ {phantomLimits.spatialRes.toFixed(1)}</Text>
                    <Text style={styles.thirdWidth}>≤ {phantomLimits.spatialRes.toFixed(1)}</Text>
                    <Text style={styles.lowContrastDetect} />
                    <Text style={styles.headNumber}>Y/N</Text>
                    <Text style={styles.name} />
                    <Text style={styles.noteWidth} />
                    <Text style={styles.name} />
                  </View>

                  {record.entries.map((entry) => (
                    <View
                      key={entry._id}
                      style={{
                        borderBottomWidth: 0.1,
                        flexDirection: 'row',
                      }}
                    >
                      <Text style={styles.thirdWidth}>{parseDate(entry.date)}</Text>
                      <Text style={styles.headNumber}>{entry.setupAccuracy}</Text>
                      <Text style={styles.headNumber}>{entry.setupConsole ? 'Y' : 'N'}</Text>
                      <Text style={styles.headNumber}>{entry.centerFreq}</Text>
                      <Text style={styles.headNumber}>{entry.txGainAtten}</Text>
                      <Text style={styles.thirdWidth}>{entry.geoAccuracyHf}</Text>
                      <Text style={styles.thirdWidth}>{entry.geoAccuracyAp}</Text>
                      <Text style={styles.thirdWidth}>{entry.geoAccuracyRl}</Text>
                      <Text style={styles.thirdWidth}>{entry.spatialResUpper}</Text>
                      <Text style={styles.thirdWidth}>{entry.spatialResLower}</Text>
                      <Text style={styles.lowContrastDetect}>{entry.spokesNum}</Text>
                      <Text style={styles.headNumber}>{entry.artifactEval ? 'Y' : 'N'}</Text>
                      <Text style={styles.name}>{entry.initials}</Text>
                      <Text style={styles.noteWidth}>{entry.notes}</Text>
                      <Text style={styles.name}>{`${entry.reviewedBy} on ${parseDate(entry.reviewedDate)}`}</Text>
                    </View>
                  ))}
                </View>
                <Text style={styles.copyRight}>© 2020 Radiology QC Solutions LLC</Text>
              </Page>
            );
          })
        : null}
    </Document>
  );
}

QaPdfPreview.propTypes = {
  records: PropTypes.arrayOf(PropTypes.any),
  device: PropTypes.objectOf(PropTypes.any),
};

QaPdfPreview.defaultProps = {
  records: [],
  device: null,
};

export default QaPdfPreview;
